.contentTable {
  border-top: 1px solid #eaecf0;
  border-radius: 0px 0px 12px 12px;
  margin-top: 5px;
  margin-bottom: 5%;
  --tw-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000),
    var(--tw-shadow);

  .rs-table-cell {
    margin-bottom: 0px;
  }

  .paginationButton {
    padding: 20px;
    display: flex;
    justify-content: space-between;

    .contentButton {
      margin-right: 5px;
    }

    .contentPage {
      display: flex;
      flex: 1;

      .pagination {
        display: flex !important;
        flex: 1 !important;
      }
    }
  }
}
