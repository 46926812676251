@charset "utf-8";

// COLORS
$theme-colors: (
  'primary': #000080,
  'secondary': #a9b5ba,
  'success': #28a745,
  'danger': #ff4136,
  'warning': #ffc107,
  'info': #3caedf,
  'light': #f8f9fa,
  'dark': #343a40,
  'white': #ffffff,
  'transparent': transparent
);

@font-face {
  font-family: 'LatoRegular';
  src: url('./Lato-Regular.ttf');
  font-color: 'darkblue';
}

.ia-table {
  font-size: 22px;
}

.nav-link {
  color: navy !important;
}

.nav {
  --bs-nav-link-color: navy !important;
}

.page-link.active {
  color: navy !important;
  border-color: navy !important;
}

.page-link.active,
.active > .page-link {
  color: white !important;
  border-color: navy !important;
}

.col-center {
  display: flex;
  justify-content: center;
}

// TEXT
$font-family-sans-serif: LatoRegular;
$font-family-serif: Georgia, 'Times New Roman', Times, serif;
$font-family-base: $font-family-sans-serif;
$font-size-base: 1rem;

// BREAKPOINTS
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  2xl: 1400px
);

// SEE DOCS FOR MORE:
// https://bit.ly/2sgFMdb

// IMPORT BOOTSTRAP
@import '~bootstrap/scss/bootstrap';

// IMPORT FONT AWESOME
@import url('https://use.fontawesome.com/releases/v5.10.1/css/all.css');

#root {
  // Full height of screen and flex so that we can push footer
  // to the bottom even if content is shorter than screen
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  // Push footer to the bottom of the screen
  > .footer {
    margin-top: auto;
  }

  // Prevent child elements from shrinking when content
  // is taller than the screen (quirk of having flex parent)
  > * {
    flex-shrink: 0;
  }

  .react-bootstrap-table table {
    table-layout: auto;
  }

  .table-condensed {
    font-size: 10px;
  }
}

.tri-tabs {
  justify-content: center;
  font-size: 22px;
}

.chart-status-section {
  margin-bottom: 8px;
  display: flex;
  justify-content: center;
}

.pagination {
  justify-content: right;
  --bs-pagination-color: navy;
  --bs-pagination-bg: white;
  --bs-pagination-focus-color: navy;
  --bs-pagination-active-bg: navy;
}

.top-container,
.main-container {
  display: flex;
}

.top-container {
  margin-bottom: 25px;
  margin-left: 20%;
  margin-right: 20%;
  justify-content: space-between;
}

.top-container div {
  display: flex;
  align-items: center;
}

.location-select {
  min-width: 400px;
}

.instrument-dropdowns {
  margin-bottom: 15px;
  margin-left: 10%;
  margin-right: 10%;
}

.colored-button {
  margin-top: 5%;
  height: 13px;
  width: 13px;
  background-color: blue;
  border-radius: 100%;
  margin-right: 4px;
}

.location-label {
  margin-right: 10px;
}

.variable-column,
.value-column,
.text-column {
  border-right: 1px solid #dee2e6;
  border-top: 1px solid #dee2e6;
  border-bottom: 1px solid #dee2e6;
  border-left: 1px solid #dee2e6;
  padding: 0.3rem;
  font-weight: bold;
  display: flex;
  align-items: center;
  width: 17%;
}

.global-dropdowns {
  margin-left: 4%;
  margin-right: 4%;
}

.text-column {
  width: 43% !important;
}

.value-column select,
.text-column input,
.value-column input {
  border: none;
}

.value-column input {
  margin-left: 8px;
}

textarea {
  border: none;
  width: 100%;
}

.value-column {
  border-right: 1px solid #dee2e6;
  border-top: 1px solid #dee2e6;
  border-bottom: 1px solid #dee2e6;
  border-left: 1px solid #dee2e6;
  padding: 0.3rem;
  font-weight: bold;
  width: 10% !important;
}

.indent {
  padding-left: 35px;
  font-size: 16px !important;
}

.bold-header {
  font-weight: bold !important;
}

.notification-row {
  display: flex;
  justify-content: center;
}

.bottom-section {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 25px;
}

.bottom-section div {
  text-align: center;
}

.bottom-text {
  margin-top: 25px;
  margin-left: 23%;
  margin-right: 23%;
  margin-bottom: 35px;
}

.edit-button button {
  margin-left: 4px;
  margin-right: 4px;
}

.demo-mode-container {
  text-align: center;
}

.demo-mode-container Button {
  font-size: 10px;
  margin-left: 4px;
  margin-right: 4px;
}

.overview-header {
  display: flex;
  justify-content: center;
}

.overview-chart-header h4 {
  font-size: 20px;
  font-weight: bold;
}

.charts-master-container > div {
  padding-left: 15px;
  padding-right: 15px;
}

.chart-status-section > div {
  display: flex;
  align-items: center;
}

.chart-status-section > div > h3 {
  font-size: 16px;
  margin-bottom: 0;
  margin-right: 8px;
  margin-left: 8px;
}

.instruments-chart-container {
  font-size: 20px !important;
  display: flex;
  justify-content: left !important;
}

.please-select-container {
  display: flex;
  justify-content: center;
}

.configuration-table-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.left-configuration-tables {
  margin-right: 15px;
}

.right-configuration-tables {
  margin-left: 15px;
}

.button-container {
  display: flex;
  justify-content: center;
}

.add-schedule-container {
  display: flex;
  justify-content: right;
}

.restore-archive-container {
  display: flex;
  justify-content: right;
}

.compiling-issue-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10%;
  margin-right: 10%;
}

.compiling-issue-header h4 {
  font-size: 20px;
  font-weight: bold;
}

.compiling-issue-header {
  display: block;
}

.SectionComponent:not(.footer) .container {
  max-width: 100%;
}

.custom-tooltip {
  padding: 0.5rem 1rem;
  background: white;
  border: 1px solid darkgray;
  p {
    margin: 0;
  }
}

@media print {
  /* Hide non-printable elements */
  .no-print {
    display: none !important;
  }

  /* Force background colors/images */
  * {
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
  }

  /* Add page breaks where needed */
  .page-break {
    page-break-before: always;
  }
}
