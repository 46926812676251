.icon-button {
  cursor: pointer;
}

.data-entry-container {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 20px;
  margin-right: 4%;
}

.data-entry-container Col {
  height: 300px;
}

.data-button-container {
  display: flex;
  justify-content: space-between;
  margin-left: 15px;
  margin-right: 15px;
}

.left-buttons {
  display: flex;
}

.left-buttons div {
  margin-right: 15px;
}

.header-button-container {
  display: flex;
  justify-content: center;
}
