.contentRepost {
  padding-bottom: 2rem;

  .contentSelect {
    display: flex;
    width: 100%;
    gap: 0.5rem;

    .contentSelectView {
      display: flex;
      flex-direction: column;
      width: 100%;

      .rs-picker-default .rs-picker-toggle.rs-btn {
        padding-bottom: 7px;
        padding-top: 7px;
      }
    }
  }

  .contentParamtG {
    display: flex;
    margin-top: 2%;

    .title {
      font-style: normal;
      font-weight: 600;
      font-size: 30px;
      line-height: 38px;
    }

    .contentSelectView {
      display: flex;
      flex-direction: column;
      margin-left: 10px;

      .select {
        width: 266px;
        height: 44px;
        margin-right: 0.5rem;
      }

      .rs-picker-default .rs-picker-toggle.rs-btn {
        padding-bottom: 7px;
        padding-top: 7px;
        height: 44px;

        background-color: #d0d5dd !important;
      }
    }
  }
  .topTable {
    display: flex;
    flex-direction: row;
    background: #f1f1f1;
    padding: 20px 20px 0px 20px;
    border-radius: 12px 12px 0 0;

    .title {
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 30px;
    }

    .contentPerformance {
      width: 65%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      /* Base/White */

      .contentInput {
        display: flex;
        justify-content: space-between;

        .contentInputView {
          display: flex;
        }
      }
    }

    .noteContent {
      margin-left: 20px;
      width: 35%;

      & ul {
        padding-inline-start: 0px;
      }
      & ol {
        padding-inline-start: 10px;
        padding-bottom: 4px;
      }
    }
  }

  .contentGrafic {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 20px;
    margin-bottom: 2%;

    /* Base/White */

    background: #eeeeee;

    border-radius: 0 0 12px 12px;

    .styleGrafic {
      box-sizing: border-box;

      /* Auto layout */

      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 0;

      /* Base/White */

      background: #ffffff;
      /* Gray/200 */

      border: 1px solid #eaecf0;
      /* Shadow/sm */

      box-shadow: 0 1px 3px rgba(16, 24, 40, 0.1), 0 1px 2px rgba(16, 24, 40, 0.06);
      border-radius: 8px;

      /* Inside auto layout */

      flex: none;
      order: 0;
      align-self: stretch;
      flex-grow: 0;

      text {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
      }

      svg {
        border-radius: 8px;
      }
    }
  }
}
