.contentExternal {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 5%;
  margin-top: 3%;
  gap: 2rem;

  .title {
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 38px;
  }

  .contentFilter {
    display: flex;
    align-items: flex-end;

    .contentSelectView {
      display: flex;
      flex-direction: column;

      .select {
        width: 266px;
        height: 44px;
        margin-right: 0.5rem;
      }

      .rs-picker-default .rs-picker-toggle.rs-btn {
        padding-bottom: 7px;
        padding-top: 7px;
        height: 44px;
      }
    }

    .btnFilter {
      width: 170px;
      height: 44px;
      background-color: #306aac;
    }
  }
}

.contentFile {
  display: flex;
  background-color: #f2f4f7;
  padding: 26px;
  justify-content: space-between;
  align-items: center;

  .labelFile {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
  }

  .btnFile {
    width: 137px;
    height: 40px;
    margin-right: 16px;
  }
}

.contentFilterG {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 1%;
  margin-top: 2%;

  .title {
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 38px;
  }

  .btnPrint {
    width: 170px;
    height: 44px;
    background-color: #306aac;
  }
}

.contentSelectFilterG {
  display: flex;
  justify-content: space-between;
  gap: 1rem;

  .contentSelectView {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .select {
      min-width: 10vw;
      max-width: 20vw;
      width: 100%;
      height: 44px;
      margin-right: 0.5rem;
    }

    .rs-picker-default .rs-picker-toggle.rs-btn {
      padding-bottom: 7px;
      padding-top: 7px;
      height: 44px;
    }
  }
}

.contentParamtG {
  display: flex;
  margin-top: 2%;

  .title {
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 38px;
  }

  .contentSelectView {
    display: flex;
    flex-direction: column;
    margin-left: 10px;
    gap: 1rem;

    .select {
      width: 100%;
      height: 44px;
      margin-right: 0.5rem;
    }

    .rs-picker-default .rs-picker-toggle.rs-btn {
      padding-bottom: 7px;
      padding-top: 7px;
      height: 44px;

      background-color: #d0d5dd !important;
    }
  }
}

.contentDonwload {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-top: 2%;

  .contentAllSelect {
    display: flex;
    justify-content: space-between;

    .contentSelectView {
      display: flex;
      flex-direction: column;
      gap: 1rem;

      .select {
        width: 100%;
        height: 44px;
        margin-right: 0.5rem;
      }

      .rs-picker-default .rs-picker-toggle.rs-btn {
        padding-bottom: 7px;
        padding-top: 7px;
        height: 44px;
      }
    }
  }

  .btnDownload {
    width: 170px;
    height: 44px;
    background-color: #306aac;
  }
}

.contentNav {
  margin-top: 2%;
}

.contentTablesType {
  display: flex;
  justify-content: space-between;
  margin-top: 2%;

  .contentTableOne {
    width: 100%;
    border-radius: 12px;
    --tw-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
    --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000),
      var(--tw-shadow);

    .contentTitle {
      padding: 15px;

      .title {
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 28px;
      }
    }
  }
}

.tabHidden {
  display: none;
}
