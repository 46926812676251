.personnel-information-container,
.setup-group {
  margin-left: 15px;
  margin-right: 15px;
}

.personnel-information-container h3,
.setup-group h3 {
  margin-bottom: 15px;
}

.setup-group {
  margin-top: 25px;
}

.underline-header {
  margin-right: 30%;
  margin-left: 30%;
}

.underline-header hr {
  width: 100%;
}

.setup-groups-header {
  display: flex;
  justify-content: center;
}

.permission-row {
  display: flex;
  flex-direction: row;
  padding: 8px 0;
}

.permission-update {
  display: flex;
  flex-direction: row;
}
.checkbox-title {
  padding: 0 16px;
}

.permission-title {
  padding: 0 16px;
}
