.LongContent {
  h1 {
    font-size: 2.125rem;
    margin-bottom: 0.75rem;
  }

  h2 {
    font-size: 1.75rem;

    &:not(:first-child) {
      margin-top: 2rem;
    }
  }

  h3 {
    font-size: 1.25rem;

    &:not(:first-child) {
      margin-top: 1.5rem;
    }
  }
}
