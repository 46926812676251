.assessment-row {
  display: flex;
  justify-content: space-between;
  max-width: 1550px;
}

.assessment-row-btn-group {
  display: flex;
  justify-content: center;
  gap: 4px;
}

@media print {
  .assessment-row {
    max-width: 1000px;
  }

  .page-three-container {
    margin-left: 0.5%;
    margin-right: 0.5%;
    min-width: fit-content;
  }
}
