.left-kpi-container {
  margin-left: 4%;
  margin-top: 20px;
  margin-right: 15px;
}

.left-kpi-container Card {
  display: flex;
  justify-content: center;
  margin-top: 5px;
  margin-bottom: 5px;
}

.chart-title {
  text-align: center;
}

.chart-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.kpi-header {
  margin-bottom: 15px;
}

.card-class {
  margin-top: 20px;
  margin-bottom: 20px;
}

.full-kpi-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.rvp-check-container {
  margin-inline: 2%;
}

.rvp-check-table {
  width: 100%;
  border-collapse: collapse;
}

.rvp-check-table th,
.rvp-check-table td {
  border: 1px solid #ccc;
  padding: 10px;
  text-align: left;
}

.rvp-check-table th {
  background-color: #e9ecef;
}

.right-kpi-container {
  margin-right: 4%;
  padding-top: 60px;
  margin-left: 15px;
}

.kpi-select {
  display: flex;
  justify-content: right;
  margin-bottom: 20px;
  margin-left: 60%;
}

.rvp-filters {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  margin-bottom: 20px;
  gap: 15px;

  .form-group {
    margin-bottom: 0;
    min-width: 150px;
  }
}

.kpi-table-container {
  height: 460px;
}

.kpi-ooc-warning-table-container {
  height: 560px;
}

.chart-details-container {
  margin-left: 4%;
  margin-right: 4%;
}

.flag-definitions-container {
  display: flex;
  justify-content: space-evenly;
}

.flag-definitions-card {
  margin-left: 8%;
  margin-right: 8%;
}

.flag-row {
  display: flex;
}

.flag {
  font-weight: 600;
  margin-right: 8px;
}

.status-container {
  text-align: center;
  margin-left: 4%;
  margin-right: 4%;
}

.status-header {
  margin-top: 15px;
  margin-bottom: 20px;
}
