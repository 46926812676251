.contentScreen {
  /* display: flex; */
  width: 80vw;

  .content {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 5%;
    margin-top: 3%;

    .title {
      font-style: normal;
      font-weight: 600;
      font-size: 30px;
      line-height: 38px;
    }
  }

  .contentNav {
    display: flex;
    justify-content: center;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .tabHidden {
    display: none;
  }
}
