.org-table-container-start {
  margin-right: 15px;
}

.org-table-container-end {
  margin-left: 15px;
}

.org-table-container {
  margin-left: 15px;
  margin-right: 15px;
}

.organization-table-container {
  margin-top: 35px;
  margin-right: 20px;
  margin-left: 20px;
}

.add-row-container {
  text-align: center;
  width: 100%;
}

.header {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  flex-direction: column;
}

hr {
  width: 40%;
  margin-top: 0;
}

.header h2 {
  font-size: 28px;
}

.data-button-container {
  margin-bottom: 15px;
}
