.chart-spc-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 50px;
}

.btn-center {
  display: flex;
  justify-content: center;
}

.info-spc-item {
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  background-color: #f8f9fa;
  border-left: 5px solid #007bff;
}

h3.info-spc-container {
  font-size: 16px;
  font-weight: 600;
  color: #333;
}

.info-spc-container {
  display: flex;
  padding: 10px;
  background-color: #f8f9fa;
  border-radius: 5px;
}

.info-spc-label {
  font-weight: bold;
  color: #007bff;
  margin-right: 5px;
  margin-left: 25px;
}

@media print {
  @page {
    size: A4 landscape;
  }

  body {
    width: 2000px;
    transform: scale(0.85);
    transform-origin: top left;
  }

  .info-spc-item {
    transform: scale(0.95);
    height: fit-content;
    width: fit-content;
    padding: 5px;
    margin-bottom: 5px;
    border-radius: 5px;
    background-color: #f8f9fa;
    border-left: 1px solid #007bff;
  }
}
